import React from "react";
import { useState, useEffect } from 'react';
import Navbar from "./components/navbar/Navbar";
import Accueil from "./Pages/Accueil/Accueil";
import MonCompte from "./Pages/MonCompte/MonCompte";
import Information from "./Pages/MonCompte/Information";
import Candidats from "./Pages/MesCV/Candidats";
import UserCVs from "./Pages/MesCV/UserCVs";
import MonEquipe from "./Pages/MonEquipe/MonEquipe";
import Client from "./Pages/Client/Client";
import EspaceClient from "./Pages/Client/EspaceClient";
import AppelOffre from "./Pages/Client/AppelOffre";
import TestsTechnique from "./Pages/TestsTechnique/TestsTechnique";
import TestsCompliance from "./Pages/TestsTechnique/TestsCompliance";
import RechercheAvance from "./Pages/RechercheAvance/RechercheAvance";
import Footer from "./components/footer/Footer";
import EditionCv from "./Pages/EditionCv/EditionCv";
import Error404 from "./components/assets/Error404";
import Error403 from "./components/assets/Error403";
import InscriptionCandidat from "./Pages/Inscription/InscriptionCandidat";
import InscriptionClient from "./Pages/Inscription/InscriptionClient";
import ResetPassword from "./Pages/Inscription/ResetPassword";
import Activation from "./Pages/Inscription/Activation";
import Inscription from "./Pages/Inscription/Inscription";
import SuperAdmin from "./Pages/SuperAdmin/SuperAdmin";
import ValidationForm from "./Pages/SuperAdmin/ValidationForm";
import EnvoyerMessage from "./Pages/SuperAdmin/EnvoyerMessage";
import Mentions from "./Pages/Legales/Mentions";
import CGU from "./Pages/Legales/CGU";
import Politique from "./Pages/Legales/Politique";
import Contact from "./components/footer/Contact";
import jwt_decode from 'jwt-decode';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import ErrorBoundary from './components/assets/ErrorBoundary';
import Loader from "./components/assets/Loader";
import { useDarkMode } from './components/assets/DarkModeContext';
import { AuthGuard } from "./components/SSO/AuthGuard";
import { getProfile, updateLastLoginDate } from "./components/services/httpService";
import useAuth from "./components/SSO/useAuth";
import CVPreview from "./Pages/Client/CVPreview";

function App() {
  const [userProfile, setUserProfile] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const auth = useAuth();

  useEffect(() => {
    const fetchProfileAndUpdateLoginDate = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decoded = jwt_decode(token);
        if (decoded.exp < Date.now() / 1000) {
          localStorage.removeItem('token');
          auth.displayLogin();
        } else {
          try {
            const response = await getProfile();
            const data = await response.json();
            setUserProfile(data);
            setUserRole(data.role);
            await updateLastLoginDate(data.id); // Await the async function
          } catch (error) {
            console.error("Erreur lors de la récupération du profil :", error);
            auth.logout();
          }
        }
      } else {
        setLoading(false);
      }
    };
  
    fetchProfileAndUpdateLoginDate();
  }, []);

  useEffect(() => {
    // Simule un délai de chargement de 2 secondes
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const className = 'disable-transitions';
    if (isDarkMode) {
      document.body.classList.add(className);
      setTimeout(() => document.body.classList.remove(className), 1000);
    }
  }, [isDarkMode]);

  const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => (
    <Route {...rest} render={(props) => {
      if (loading) {
        return <Loader />;
      }

      if (!auth || !allowedRoles.includes(userRole)) {
        return <Redirect to="/" />;
      }

      return <Component {...props} />;
    }} />
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {auth ? <Navbar userProfile={userProfile} /> : null}
          <Switch>
            <Route exact path="/" render={() => (
              <Accueil setUserRole={setUserRole} />
            )} />
            {/* <PrivateRoute path="/inscription" component={Inscription} allowedRoles={['MANAGER', 'SUPERADMIN']} /> */}
            <Route path="/inscription-candidat" component={InscriptionCandidat} />
            <Route path="/inscription-client" component={InscriptionClient} />
            <Route path="/activate/:token" component={Activation} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/mon-compte">
              <MonCompte />
            </Route>
            <Route path="/mes-informations" render={() => (
              <Information />
            )} />
            <PrivateRoute
              path="/candidats"
              component={Candidats}
              allowedRoles={['MANAGER', 'SUPERADMIN']}
            />
            <PrivateRoute
              path="/user-cvs/:userId"
              component={UserCVs}
              allowedRoles={['MANAGER', 'SUPERADMIN']}
            />
            <PrivateRoute path="/mon-equipe" component={MonEquipe} allowedRoles={['MANAGER', 'SUPERADMIN']} />
            <PrivateRoute path="/client" component={Client} allowedRoles={['MANAGER', 'SUPERADMIN']} />
            <PrivateRoute path="/espace-client/id/:id" component={EspaceClient} allowedRoles={['MANAGER', 'SUPERADMIN', 'CLIENT']} />
            <PrivateRoute path="/espace-client/name/:accountName" component={EspaceClient} allowedRoles={['MANAGER', 'SUPERADMIN', 'CLIENT']} />
            <PrivateRoute path="/appel-offre/:id" component={AppelOffre} allowedRoles={['MANAGER', 'SUPERADMIN', 'CLIENT']} />
            <Route path="/mentions-legales" render={() => (
              <Mentions />
            )} />
            <Route path="/cgu" render={() => (
              <CGU />
            )} />
            <Route path="/politique" render={() => (
              <Politique />
            )} />
            <Route path="/aide" render={() => (
              <Contact />
            )} />
            <Route path="/tests-techniques" render={() => (
              <AuthGuard>
                <TestsTechnique />
              </AuthGuard>
            )} />
            <Route path="tests-compliance" render={() => (
              <TestsCompliance />
            )} />
            <Route path="/mon-espace-cv/:userId/:cvId" render={() => (
              <EditionCv />
            )} />
            <Route path="/cv-preview/:userId/:cvId" render={() => (
              <CVPreview />
            )} />
            <PrivateRoute path="/recherche-avance" component={RechercheAvance} allowedRoles={['MANAGER', 'SUPERADMIN']} />
            <PrivateRoute path="/super-admin" component={SuperAdmin} allowedRoles={['SUPERADMIN']} />
            {/* <Route path="/validation" render={() => (
              <ValidationForm />
            )} /> */}
            <Route path="/validation/:token/:dataDuration?" render={(props) => <ValidationForm {...props} user={userProfile} />} />
            <Route path="/envoyer-message" render={() => (
              <EnvoyerMessage />
            )} />
            <Route render={() => (
              <Error404 />
            )} />
            <Route path="/403" render={() => (
              <Error403 />
            )} />
          </Switch>
          <Footer />
        </div>
      )}
    </>
  );

}

export default App;
